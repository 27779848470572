.floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 80vh;
  }
  
  .floatingedges .react-flow__handle {
    opacity: 0;
  }

  .react-flow {
    height:80vh!important;
  }

  .react-flow__node {
    color: black!important;
  }